import fonts from './fonts'

const textStyles = {
  titleH1: `
    ${fonts.UniversBoldCond}
    font-size: 3rem;
    line-height: 1;
    text-transform: uppercase;
  `,
  titleH3: `
    ${fonts.UniversBoldCond}
    font-size: 1.8rem;
    line-height: 1;
    text-transform: uppercase;
  `,
  text: `
    ${fonts.UniversRoman}
    font-size: 1.4rem;
    line-height: 1.5;
  `,
  label: `
    ${fonts.UniversRoman}
    font-size: 1.4rem;
    line-height: 1;
    text-transform: uppercase;
  `,
  locale: `
    ${fonts.UniversRoman}
    font-size: 1.2rem;
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  `,
}

export default textStyles
