import { declareFont, defineFont, fontWeights, Font } from '../utils/FontUtils'

type Declarations = {
  OpenSans: Font
  UniversRoman: Font
  UniversCondensed: Font
  UniversBoldCond: Font
}

type Fonts = { [key in keyof Declarations]: string }

export const declaration: Declarations = {
  OpenSans: {
    basename: 'OpenSans-Regular',
    fontFamily: 'OpenSans',
    fontWeight: fontWeights.regular,
    fallback: 'sans-serif',
  },
  UniversRoman: {
    basename: 'UniversLTCYR-55Roman',
    fontFamily: 'UniversLTCYR-55Roman',
    fontWeight: fontWeights.regular,
    fallback: 'sans-serif',
  },
  UniversCondensed: {
    basename: 'UniversLTCYR-57Condensed',
    fontFamily: 'UniversLTCYR-57Condensed',
    fontWeight: fontWeights.regular,
    fallback: 'sans-serif',
  },
  UniversBoldCond: {
    basename: 'UniversLTCYR-67BoldCond',
    fontFamily: 'UniversLTCYR-67BoldCond',
    fontWeight: fontWeights.regular,
    fallback: 'sans-serif',
  },
}

export const fontsFaces = (): string => Object.values(declaration).map(declareFont).join(' ')

const fonts: Fonts = {
  OpenSans: defineFont(declaration.OpenSans),
  UniversRoman: defineFont(declaration.UniversRoman),
  UniversBoldCond: defineFont(declaration.UniversBoldCond),
  UniversCondensed: defineFont(declaration.UniversCondensed),
}

export default fonts
