module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Balenciaga - RH","short_name":"Balenciaga - RH","start_url":"/","background_color":"#0F2127","theme_color":"#0F2127","display":"standalone","orientation":"portrait-primary","lang":"fr","icon":"static/images/favicon/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"54045a0af7e7059fed0538bb173702df"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/var/www/html/src/intl","languages":["fr","en"],"defaultLanguage":"en","redirect":true},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    }]
